.font-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #4E94D8;
}

.font-description {
  font-family: "Lucida Console", "Courier New", monospace;
  font-weight: 400;
}
